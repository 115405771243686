import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import '@/assets/styles/sass/app.scss';
import i18n from './i18n'
import setupPlugins from '@/setup/setup-plugins'
import * as Sentry from '@sentry/vue'

Vue.use(setupPlugins)

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    environment: process.env.NODE_ENV
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
